var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标签名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"标签编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'code',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tagDesc',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'tagDesc',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n        'status',\n        { rules: [{ required: true, message: '请选择！' }] },\n        ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 正常 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 禁用 ")])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }